import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'PtlvVDtplJCIOm9zISaprb2RY7ZOEIFiw5ZS',
  sdkSecret: 'jf1S5YaNg1236KAE51uw1q0CbsCDAAeHqO46',
  topic: 'shanghai_test_topic',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '',
  signature: '',
};
